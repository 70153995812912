import React, { useEffect, useState } from "react"
import styled from "styled-components"
import colors from "../../styles/colors"
import breakpoints from "../../styles/breakpoints"
import fonts from "../../styles/fonts"
import ContentContainer from "../Core/ContentContainer"
import { Col, Container, Row } from "styled-bootstrap-grid"
import ExternalLink from "../Core/ExternalLink"
import DarkTitle from "../Core/DarkTitle"
import Img from "gatsby-image"
import { graphql, useStaticQuery } from "gatsby"

const SocialBlockContainer = styled.div`
  margin: 100px 0 100px 0;
  h3 {
    line-height: 24px;
  }

  @media (min-width: ${breakpoints.md}) {
    margin: 100px 0 150px 0;
    h3 {
      line-height: 35px;
    }
  }
`

const InstagramLink = styled(ExternalLink)`
  font-size: 18px;
  line-height: 30px;
  ${fonts.ralewayMedium};
  color: ${colors.darkGrey};
  text-decoration: none;
  display: block;
  text-align: center;
  margin-top: 10px;
  margin-bottom: 30px;
  letter-spacing: 0.36px;

  @media (min-width: ${breakpoints.sm}) {
    margin-top: 20px;
    margin-bottom: 27px;
  }
`

const Card = styled.div`
  padding-right: ${(props) => (props.isEven ? "0" : "4px")};
  padding-left: ${(props) => (props.isEven ? "4px" : "0")};
  padding-bottom: ${(props) => (props.lastTwo ? "0" : "8px")};

  @media (min-width: ${breakpoints.md}) {
    padding-right: 12px;
    padding-left: 12px;
    padding-bottom: ${(props) => (props.lastFour ? "0" : "20px")};
  }
`

const Block = styled.div`
  @media (min-width: ${breakpoints.md}) {
    margin-left: -12px;
    margin-right: -12px;
  }
`

const FEED_IMAGE_COUNT = 8

const InstaFeed = () => {
  const data = useStaticQuery(graphql`
    query InstagramQuery {
      insta_01: file(relativePath: { eq: "insta/01.jpg" }) {
        childImageSharp {
          fluid(quality: 100, maxWidth: 528, maxHeight: 528) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
      insta_02: file(relativePath: { eq: "insta/02.jpg" }) {
        childImageSharp {
          fluid(quality: 100, maxWidth: 528, maxHeight: 528) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
      insta_03: file(relativePath: { eq: "insta/03.jpg" }) {
        childImageSharp {
          fluid(quality: 100, maxWidth: 528, maxHeight: 528) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
      insta_04: file(relativePath: { eq: "insta/04.jpg" }) {
        childImageSharp {
          fluid(quality: 100, maxWidth: 528, maxHeight: 528) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
      insta_05: file(relativePath: { eq: "insta/05.jpg" }) {
        childImageSharp {
          fluid(quality: 100, maxWidth: 528, maxHeight: 528) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
      insta_06: file(relativePath: { eq: "insta/06.jpg" }) {
        childImageSharp {
          fluid(quality: 100, maxWidth: 528, maxHeight: 528) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
      insta_07: file(relativePath: { eq: "insta/07.jpg" }) {
        childImageSharp {
          fluid(quality: 100, maxWidth: 528, maxHeight: 528) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
      insta_08: file(relativePath: { eq: "insta/08.jpg" }) {
        childImageSharp {
          fluid(quality: 100, maxWidth: 528, maxHeight: 528) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
    }
  `)

  const {insta_01, insta_02, insta_03, insta_04, insta_05, insta_06, insta_07, insta_08} = data;

  const feedData = [
    {image: insta_01, id: "CQxxa1iLLdu"},
    {image: insta_02, id: "CQvyGa9rc2Z"},
    {image: insta_03, id: "CQvxhroLUHu"},
    {image: insta_04, id: "COBaVhjrMcA"},
    {image: insta_05, id: "CNLMqIfLZV_"},
    {image: insta_06, id: "CJAnlVyLFtQ"},
    {image: insta_07, id: "CI9Btg2rjoi"},
    {image: insta_08, id: "CI878FFrevR"},
  ]

  let feedImages = [];

  for (let i = 0; i < FEED_IMAGE_COUNT; i++) {
    feedImages.push(feedData[i]);
  }

  const [cards, setCards] = useState(feedImages);

  const changeAmountOfCards = () => {
    if (window.innerWidth < 768 && cards.length !== 4) {
      setCards(cards.slice(0, 4))
    }
    else if (window.innerWidth >= 768 && cards.length === 4) {
      setCards(feedImages)
    }
  }

  useEffect(() => {
    if (typeof window === undefined) {
      return;
    }
    changeAmountOfCards();
    window.addEventListener("resize", () => {
      changeAmountOfCards()
    })
    return () => {
      window.removeEventListener("resize", () => {
        changeAmountOfCards()
      })
    }
  })

  return (
    <SocialBlockContainer>
      <Container>
        <ContentContainer>
          <DarkTitle>Suivez nous</DarkTitle>
          <InstagramLink href={"https://www.instagram.com/stephensaada/"}>@stephensaada</InstagramLink>
          <Block>
            <Row>
              {cards.map((card, index) => {
                return (
                  <Col xs={6} sm={6} md={3} key={index}>
                    <Card isEven={index % 2} lastFour={index > 3} lastTwo={index > 1}>
                      <ExternalLink href={`https://www.instagram.com/p/` + card.id}>
                        <Img fluid={card.image.childImageSharp.fluid} alt={"Instagram"}/>
                      </ExternalLink>
                    </Card>
                  </Col>
                )
              })}
            </Row>
          </Block>
        </ContentContainer>
      </Container>
    </SocialBlockContainer>
  )
}

export default InstaFeed
