import React from "react";
import styled from 'styled-components';
import breakpoints from "../../styles/breakpoints";
import Img from "gatsby-image";
import Overlay from "../Core/Overlay";
import LogoImage from "../../resources/img/logo-white.svg";

const IntroContainer = styled.div`
  position: relative;
  margin: 10px;
  
  @media(min-width: ${breakpoints.sm}){
    margin: 30px;
  }
`;

const ImgStyled = styled(Img)`
  height: calc(100vh - 20px);
  @media(min-width: ${breakpoints.sm}){
    height: calc(100vh - 60px);
  }
`;

const LogoContainer = styled.div`
  height: 100%;
  width: 100%;
  padding-left: 36px;
  padding-right: 35px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  position: absolute;
  top: 0;
  z-index: 10;
  
  @media(min-width: ${breakpoints.md}){
    padding-right: 166px;
    padding-left: 166px;
  }
`;

const Logo = styled.img`
  width: 100%;
  min-width: 288px;
  
  @media(min-width: ${breakpoints.sm}){
    max-width: 888px;
  }
`;

const Hero = ({image}) => {

  return (
    <IntroContainer>
      <Overlay/>
      <ImgStyled fluid={image.fluid} loading={"eager"}/>
      <LogoContainer>
        <Logo src={LogoImage} alt={"StephenSaada Logo"}/>
      </LogoContainer>
    </IntroContainer>
  )
}

export default Hero;