import React from "react";
import styled from "styled-components";

const OverlayStyled = styled.div`
  background: rgba(0, 0, 0, 0.3);
  height: 100%;
  width: 100%;
  z-index: 2;
  position: absolute;
  top: 0;
  left: 0;
`;

const Overlay = () => (
  <OverlayStyled/>
  )

export default Overlay;