import React from "react"
import styled from "styled-components"
import colors from "../../styles/colors"
import breakpoints from "../../styles/breakpoints"
import fonts from "../../styles/fonts"
import UnderlineButton from "../Core/UnderlineButton"
import Img from "gatsby-image"
import Overlay from "../Core/Overlay"
import { Link } from "gatsby"
import IntersectionObserver from "./IntersectionObserver"

const ImageBlockContainer = styled.div`
  position: relative;
  height: 100vh;
  margin-bottom: ${props => props.last ? "0" : "10"}px;
  
  @media(min-width: ${breakpoints.sm}){
    margin-bottom: ${props => props.last ? "0" : "30"}px;
  }
`

const Type = styled.span`
  font-size: 16px;
  line-height: 30px;
  letter-spacing: 0.32px;
  ${fonts.ralewayMedium};
  color: ${colors.lightGold};
  display: block;
  pointer-events: initial;
  a {
    color: ${colors.lightGold};
    text-decoration: none;
  }
  
  @media(min-width: ${breakpoints.sm}){
    font-size: 18px;
    letter-spacing: 0.36px;
  }
`

const Title = styled.h2`
  font-size: 20px;
  line-height: 30px;
  letter-spacing: 12px;
  text-transform: uppercase;
  color: ${colors.white};
  ${fonts.ralewayRegular};
  max-width: 266px;
  margin: 20px auto 20px auto;
  
  @media(min-width: ${breakpoints.sm}){
    font-size: 40px;
    line-height: 50px;
    letter-spacing: 24px;
    max-width: 540px;
    margin: 16px auto 14px auto;
  }
`

const Content = styled.div`
  text-align: center;
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 3;
  pointer-events: none;
`

const ImgStyled = styled(Img)`
  height: 100%;
`

const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`


const Project = ({ project, isEven, isLast }) => {

  return (
    <ImageBlockContainer last={isLast}>
      <Link to={`/projet/${project.slug}`}>
        <Overlay/>
      </Link>
      <ImgStyled fluid={project.thumbnails[0].fluid} alt={project.title}/>
      <Content className="content">
        <IntersectionObserver opacity={"0"} transform={"x"} threshold={0.01} even={isEven}>
          <Type>
            <Link to={project.category.slug}>
              {project.category.title}
            </Link>
          </Type>
          <Title>{project.title}</Title>
          <ButtonContainer>
            <UnderlineButton to={`/projet/` + project.slug} white={1}>Voir le projet</UnderlineButton>
          </ButtonContainer>
        </IntersectionObserver>
      </Content>
    </ImageBlockContainer>
  )
}

export default Project
