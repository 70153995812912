import React from "react";
import Layout from "../components/Layout/Layout";
import SEO from "../components/Core/Seo";
import Hero from "../components/Homepage/Hero";
import Projects from "../components/Homepage/Projects";
import InstaFeed from "../components/Homepage/InstaFeed";
import PreFooter from "../components/Core/PreFooter";
import Categories from "../components/Homepage/Categories";

const IndexPage = (props) => {
  const path = props.location.pathname;
  const page = props.data.page;
  let blocks = page.blocks;

  if (!blocks){
    blocks = []
  }

  let pageBlocks = [];

  blocks.forEach((block, index) => {
    let blockType = block.__typename;

    if (blockType === 'ContentfulHero') {
      pageBlocks.push(<Hero key={index} image={block.image}/>)
      return;
    }

    if (blockType === 'ContentfulProjects') {
      pageBlocks.push(<Projects key={index} projects={block.projects}/>)
      return;
    }
    if (blockType === 'ContentfulCategories') {
      pageBlocks.push(<Categories key={index} categories={block.category}/>)
    }
  })

  return (
    <Layout darkStyle={false}>
      <SEO fullTitle={true}
           path={path}
           title={page.metaTitle}
           description={page.metaDescription}/>
      {pageBlocks}
      <InstaFeed/>
      <PreFooter/>
    </Layout>
  )
}

export default IndexPage;

export const pageQuery = graphql`
  query IndexQuery {
    page: contentfulPage(slug: {eq: "homepage"}) {
      title
      metaTitle
      metaDescription
      blocks {
        __typename
        ... on Node {
          ... on ContentfulHero {
            image {
              fluid(maxWidth: 1920 quality: 100) {
                ...GatsbyContentfulFluid_withWebp_noBase64
              }
            }
          }
          ... on ContentfulProjects {
            projects {
              title
              metaTitle
              metaDescription
              slug
              category {
                slug
                title
              }
              thumbnails {
                fluid(maxWidth: 1920 quality: 100) {
                  ...GatsbyContentfulFluid_withWebp_noBase64
                }
              }
            }
          }
          ... on ContentfulLinks {
            links {
              ctaLink
              ctaLabel
            }
          }
          ... on ContentfulCategories {
            category {
              title
              slug
              metaTitle
              metaDescription
              thumbnail {
                fluid(maxWidth: 528 quality: 100) {
                  ...GatsbyContentfulFluid_withWebp_noBase64
                }
              }
            }
          }
        }
      }
    }
  }
`
