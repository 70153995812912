import React, {useState} from "react";
import styled from 'styled-components';
import breakpoints from "../../styles/breakpoints";
import UnderlineButton from "../Core/UnderlineButton";
import {Container, Row, Col} from "styled-bootstrap-grid";
import ContentContainer from "../Core/ContentContainer";
import Img from "gatsby-image";
import { Link } from "gatsby";
import colors from "../../styles/colors"
import fonts from "../../styles/fonts"
import DarkTitle from "../Core/DarkTitle"
import IntersectionObersver from "./IntersectionObserver"

const Background = styled.div`
  background: ${colors.backgroundGrey};
  padding: 100px 0 100px 0;
  h3 {
    line-height: 24px;
    margin-bottom: 30px;
  }
  
  @media(min-width: ${breakpoints.sm}){
    background: ${colors.lightBackgroundGrey};
    
    h3 {
      line-height: 35px;
    }
  }
`;

const Block = styled.div`
  @media(min-width: ${breakpoints.md}){
    margin-left: -12px;
    margin-right: -12px;
  }
`;

const Card = styled.div`
  padding-right: ${props => props.isEven ? '0' : '4px'};
  padding-left: ${props => props.isEven ? '4px' : '0'};
  
  @media(min-width: ${breakpoints.md}){
    padding-right: 12px;
    padding-left: 12px;
  }
`;

const LinkContainer = styled.div`
  width: max-content;
  margin-left: auto;
  margin-right: auto;
`;

const Category = styled(Link)`
  font-size: 18px;
  line-height: 30px;
  letter-spacing: 0.36px;
  text-decoration: none;
  color: ${props => props.hover ? colors.darkGrey : colors.grey};
  ${fonts.ralewayRegular};
  text-align: center;
  display: block;
  margin-top: 10px;
  margin-bottom: 30px;
  
  @media(min-width: ${breakpoints.md}){
    margin-top: 20px;
  }
`;

const ImageContainer = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
`

const Line = styled.div`
  transition: 0.3s ease-in;
  position: absolute;
  margin: 0 auto;
  background-color: ${colors.lightGold};
  z-index: 1;
    &.line1 {
    top: 5%;
    right: 5%;
    width: ${props => props.hover ? "90%" : 0};
    height: 1px;
  }
  &.line2 {
    top: 5%;
    right: 5%;
    width: 1px;
    height: ${props => props.hover ? "90%" : 0};
  }
  &.line3 {
    bottom: 5%;
    left: 5%;
    width: ${props => props.hover ? "90%" : 0};
    height: 1px;
  }
  &.line4 {
    top: 5%;
    left: 5%;
    width: 1px;
    height: ${props => props.hover ? "90%" : "0px"};
  }
`

const Categories = ({categories}) => {
  const [hovered, setHovered] = useState(null);

  return (
    <Background>
      <IntersectionObersver threshold={0.01} opacity={"0.6"} transform="y">
        <Container>
          <ContentContainer>
            <DarkTitle>Projets</DarkTitle>
            <Block>
              <Row>
                {categories.map((project, index) => {
                  return (
                    <Col xs={6} sm={6} md={3} key={index} onMouseEnter={() => setHovered(project.title)} onMouseLeave={() => setHovered(null)}>
                      <Card isEven={index % 2}>
                        <Link to={`/` + project.slug}>
                          <ImageContainer>
                            <Img fluid={project.thumbnail.fluid} alt={""}/>
                            <Line className="line1" hover={hovered === project.title ? 1 : 0}></Line>
                            <Line className="line2" hover={hovered === project.title ? 1 : 0}></Line>
                            <Line className="line3" hover={hovered === project.title ? 1 : 0}></Line>
                            <Line className="line4" hover={hovered === project.title ? 1 : 0}></Line>
                          </ImageContainer>
                        </Link>
                        <Category to={`/` + project.slug} hover={hovered === project.title ? 1 : 0}>
                          <span>{project.title}</span>
                        </Category>
                      </Card>
                    </Col>
                  )
                })}
              </Row>
            </Block>
          </ContentContainer>
        </Container>
        <LinkContainer>
          <UnderlineButton to={"/projets"}>Tous les projets</UnderlineButton>
        </LinkContainer>
      </IntersectionObersver>
    </Background>
  )
}

export default Categories
