import React, { useState, useRef, useEffect } from "react"
import styled from "styled-components"

const Fade = styled.div`
  opacity: ${(props) => (props.visible ? 1 : props.opacity)};
  transition: opacity 1.2s ease-out, transform 1s ease-out;
  will-change: opacity, transform;
  transform: ${(props) =>
    props.visible && props.transform
      ? "none"
      : props.transform === "y"
      ? "translateY(10px)"
      : props.even
      ? "translateX(10px)"
      : "translateX(-10px)"};
`

export default function FadeInSection(props) {
  const [isVisible, setVisible] = useState(false)
  const domRef = useRef()

  let options = {
    root: domRef.current,
    rootMargin: "0px",
    threshold: props.threshold,
  }

  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry) {
          const isAbove = entry?.boundingClientRect?.y < entry?.rootBounds?.y || false;
          if (!isAbove) {
            setVisible(entry.isIntersecting)
          }
        }
      })
    }, options)
    observer.observe(domRef.current)
    return () => {
      observer.disconnect()
    }
  }, [])
  
  return (
    <Fade
      visible={isVisible}
      ref={domRef}
      opacity={props.opacity}
      transform={props.transform}
      even={props.even}
    >
      {props.children}
    </Fade>
  )
}
