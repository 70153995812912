import React from "react";
import Project from "./Project";

const Projects = ({projects}) => {
  return (
    <React.Fragment>
      {projects.map((project, index) => {
        return (
          <Project key={index} project={project} isEven={index % 2 === 0} isLast={index === 2}/>
        )
      })}
    </React.Fragment>
  )
}

export default Projects;
